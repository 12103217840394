















































































export enum BrandSetting {
  Hellow = 'hellow',
  Proxy = 'proxy',
}

import { InputSetups } from "@/mixins/input-setups";

import { UseFields } from "piramis-base-components/src/components/Pi/index";

import { Component, Mixins } from 'vue-property-decorator'
import isURL from 'validator/es/lib/isURL'
import { extend, localize, ValidationObserver } from 'vee-validate'

@Component({
  data() {
    return {
      BrandSetting
    }
  },
  components: {
    ValidationObserver
  }
})
export default class BrandBotSettings extends Mixins(UseFields, InputSetups) {
  get brandBotConfig(): any {
    return this.$store.state.chatState.brandConfig
  }

  get chatId() {
    return this.$store.state.chatState.chat.chat_id
  }

  activeBrandSetting: BrandSetting = BrandSetting.Hellow

  showPopup = false

  resetBrandingBot(): void {
    this.$confirm({
      title: this.$t("dialog_reset_chat_brand_title").toString(),
      content: this.$t("dialog_reset_chat_brand_text").toString(),
      okText: this.$t('pi_accept').toString(),
      cancelText: this.$t('pi_reject').toString(),
      onOk:(): void => {
        this.$store.dispatch('resetChatBrand')
      }
    })
  }

  saveBrandConfig(): void {
    this.$confirm({
      title: this.$t("dialog_save_brand_config_title").toString(),
      content: this.$t("dialog_save_brand_config_text").toString(),
      okText: this.$t('pi_accept').toString(),
      cancelText: this.$t('pi_reject').toString(),
      onOk: () => {
        this.$store.dispatch('saveBrandConfig', {
          chat_id: this.chatId
        })
          .then(() => this.setActiveBrandSettingByConfigField())
      }
    })
  }

  changeToHellowMessage() {
    this.changeActiveSetting(BrandSetting.Hellow)

    this.$store.commit('pi/EXEC', {
      'fn': () => {
        this.$set(this.brandBotConfig, 'proxy', '')
      },
    })

  }

  activeClass(type: BrandSetting): boolean {
    return this.activeBrandSetting === type
  }

  changeActiveSetting(type: BrandSetting) {
    this.activeBrandSetting = type
  }

  setActiveBrandSettingByConfigField() {
    if (this.brandBotConfig) {
      if (this.brandBotConfig.proxy) {
        this.changeActiveSetting(BrandSetting.Proxy)
      } else {
        this.changeActiveSetting(BrandSetting.Hellow)
      }
    }
  }

  loadValidationRules() {
    localize({
      ru: {
        messages: {
          https: 'Необходим протокол "https"',
        },
      },
      en: {
        messages: {
          https: '"https" protocol required'
        },
      },
    })

    extend('https', {
      validate: (value) => isURL(value, {
        protocols: [ 'https' ]
      }),
    })
  }

  created() {
    this.loadValidationRules()

    if (this.$store.getters.isChatLicenseExists) {
      this.$store.dispatch("getBrandConfig", { chat_id: this.chatId })
        .then(() => {
          this.setActiveBrandSettingByConfigField()
        })
    }
  }

  beforeDestroy(): void {
    this.$nextTick(() => this.$store.commit("SET_BRAND_BOT_CONFIG", null))
  }
}
