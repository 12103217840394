var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.brandBotConfig)?_c('a-card',{staticClass:"brand-bot-settings"},[_c('span',{attrs:{"slot":"title"},domProps:{"innerHTML":_vm._s(_vm.$t('brand_bot_settings_title', [_vm.$store.state.chatState.chat.brand.botname]))},slot:"title"}),_c('div',{staticClass:"flex flex-col md:flex-row gap-2 justify-center"},[_c('a-button',{class:{'active': _vm.activeClass(_vm.BrandSetting.Hellow)},attrs:{"icon":"message"},on:{"click":_vm.changeToHellowMessage}},[_vm._v(" "+_vm._s(_vm.$t(("brand_setting_" + (_vm.BrandSetting.Hellow) + "_tab_title")))+" ")]),_c('a-button',{class:{'active': _vm.activeClass(_vm.BrandSetting.Proxy)},attrs:{"icon":"link"},on:{"click":function($event){return _vm.changeActiveSetting(_vm.BrandSetting.Proxy)}}},[_vm._v(" "+_vm._s(_vm.$t(("brand_setting_" + (_vm.BrandSetting.Proxy) + "_tab_title")))+" ")])],1),_c('validation-observer',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [(_vm.activeBrandSetting === _vm.BrandSetting.Proxy)?_c('text-input',{attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.brandBotConfig,
          'key': 'proxy',
          'hasAnchor': true,
          'allowClear': true,
          'validation': 'required|url|https'
        }
      }}}):_vm._e(),(_vm.activeBrandSetting === _vm.BrandSetting.Hellow)?_c('editor-input',{attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.brandBotConfig,
          'key': 'hellow',
          'availableButtonsTypes': _vm.defaultMessageEditorButtons,
          'hasAnchor': true,
        }
      }}}):_vm._e(),_c('div',{staticClass:"flex flex-col xl:flex-row justify-end pr-2 gap-2"},[_c('a-button',{staticClass:"btn-success",attrs:{"icon":"save","disabled":invalid},on:{"click":_vm.saveBrandConfig}},[_vm._v(" "+_vm._s(_vm.$t("save_settings"))+" ")]),_c('a-button',{attrs:{"type":"danger"},on:{"click":_vm.resetBrandingBot}},[_vm._v(" "+_vm._s(_vm.$t("reset_bot_branding"))+" ")])],1)]}}],null,false,1568080797)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }