var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('centered-column-layout',{staticClass:"relative"},[_c('page-title',{attrs:{"tags":_vm.getTagsByFieldKey('branding')}}),_c('a-modal',{attrs:{"footer":null},model:{value:(_vm.isAddBrandBotTutorialOpen),callback:function ($$v) {_vm.isAddBrandBotTutorialOpen=$$v},expression:"isAddBrandBotTutorialOpen"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t('branding_description_tutorial'))}})]),_c('a-alert',{attrs:{"show-icon":"","type":_vm.getHaveBrand && _vm.getHaveLicense && _vm.isChatLicenseExists? 'warning' : 'info'}},[_c('div',{attrs:{"slot":"description"},slot:"description"},[(_vm.getHaveBrand && _vm.getHaveLicense && _vm.isChatLicenseExists)?[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('branding_bind_domain_alert'))}})]:[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('branding_description'))}}),_c('span',{staticClass:"cursor-pointer hover:opacity-75",domProps:{"innerHTML":_vm._s(_vm.$t('branding_description_2'))},on:{"click":function($event){_vm.isAddBrandBotTutorialOpen = true}}})]],2)]),(_vm.getHaveLicense)?[(_vm.getHaveBrand)?_c('brand-bot-settings',{staticClass:"mt-5"}):_c('a-card',{staticClass:"mt-5",attrs:{"title":_vm.$t('field_branding_title')}},[_c('validation-observer',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('text-input',{attrs:{"setup":{
            'func': _vm.configInputSetup,
            'args': {
              'model': _vm.form,
              'key': 'brand_bot_token',
              'validation': 'required',
              'hasAnchor': true,
            }
          }}}),_c('div',{staticClass:"flex flex-row justify-end"},[_c('a-button',{staticClass:"mt-4",attrs:{"disabled":invalid},on:{"click":function($event){return _vm.setChatBrand({ token: _vm.form.brand_bot_token })}}},[_vm._v(" "+_vm._s(_vm.$t("submit_brand_bot_token"))+" ")])],1)]}}],null,false,199033487)})],1)]:_c('div',{staticClass:"relative mt-5"},[_c('NotAvailableOptionsOverlay',{staticClass:"relative",attrs:{"description":_vm.$t('branding_gap'),"video":_vm.$i18n.locale !== 'ru' ?
        'https://client.chatkeeper.app/assets/video/video_ChatKeeperBot_branding_en.m4v' :
        'https://client.chatkeeper.app/assets/video/video_ChatKeeperBot_branding_ru.m4v'}})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }